import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
// import Image from 'gatsby-image'
// import SEO from "../components/seo"
import styled from "@emotion/styled"
import Image from "gatsby-image"
import theme from "../shared/theme"
import Button from "../elements/button"
import SeparatorLine from "../elements/separatorLine"
import DropdownSelect from "../elements/dropdownSelect"

const ProjectsStreetphotographyStartseite = () => {
  const defaultCountries = "Alle Länder"
  const [country, setCountry] = useState(defaultCountries)

  const data = useStaticQuery(graphql`
    {
      allSanityProjectStreetphotography {
        edges {
          node {
            preview_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            project_headline
            land
            alt_text
            slug {
              _key
              _type
              current
            }
          }
        }
      }
    }
  `)

  //there could be doubled entries for countries delivered by the backend - array for select values
  const laender = data.allSanityProjectStreetphotography.edges
  //We just wanna the land prop of each object of the array
  let arrayCountries = laender.map(a => a.node.land)
  let uniqueCountries = arrayCountries.filter((item, index) => {
    return arrayCountries.indexOf(item) === index
  })
  //Add default value at start of value
  uniqueCountries.unshift(defaultCountries)

  //set selected value
  const handleCountryChange = event => {
    setCountry(event.value)
  }

  return (
    <React.Fragment>
      <Section>
        <SelectContainer>
          <StyledLabel id="laenderauswahl" htmlFor="land">
            Länderauswahl
          </StyledLabel>
          <DropdownSelect
            id="land"
            defaultValue="Alle Länder"
            optionen={uniqueCountries}
            onChange={handleCountryChange}
          />
        </SelectContainer>
        <StyledPictureContainer>
          {data.allSanityProjectStreetphotography === null
            ? null
            : data.allSanityProjectStreetphotography.edges.map((item, index) =>
                country === defaultCountries ? (
                  <div key={index}>
                    {item.node.preview_image ? (
                      <Link to={`streetphotography/${item.node.slug.current}`}>
                        <Image
                          fluid={item.node.preview_image.asset.fluid}
                          title={
                            item.node.project_headline &&
                            "Street Photography " + item.node.project_headline
                          }
                          alt={item.node.alt_text && item.node.alt_text}
                        />
                      </Link>
                    ) : null}
                    <ImageSubline>
                      <div>
                        <h3>
                          {item.node.project_headline
                            ? item.node.project_headline
                            : ""}
                        </h3>
                      </div>
                      <div>
                        <Button
                          title={"zum Projekt " + item.node.project_headline}
                          ariaLabel={
                            "zum Projekt " + item.node.project_headline
                          }
                          linkTo={
                            item.node.slug
                              ? `streetphotography/${item.node.slug.current}`
                              : "/"
                          }
                        >
                          zum Projekt
                        </Button>
                      </div>
                    </ImageSubline>
                  </div>
                ) : item.node.land === country ? (
                  <div key={index}>
                    {item.node.preview_image ? (
                      <Link to={`streetphotography/${item.node.slug.current}`}>
                        <Image
                          fluid={item.node.preview_image.asset.fluid}
                          title={
                            item.node.project_headline &&
                            "Street Photography " + item.node.project_headline
                          }
                          alt={item.node.alt_text && item.node.alt_text}
                        />
                      </Link>
                    ) : null}
                    <ImageSubline>
                      <div>
                        <h3>
                          {item.node.project_headline
                            ? item.node.project_headline
                            : ""}
                        </h3>
                      </div>
                      <div>
                        <Button
                          title={"zum Projekt " + item.node.project_headline}
                          ariaLabel={
                            "zum Projekt " + item.node.project_headline
                          }
                          linkTo={
                            item.node.slug
                              ? `streetphotography/${item.node.slug.current}`
                              : "/"
                          }
                        >
                          zum Projekt
                        </Button>
                      </div>
                    </ImageSubline>
                  </div>
                ) : null
              )}
        </StyledPictureContainer>
        <StyledText>TO BE CONTINUED...</StyledText>
        <SeparatorLine />
      </Section>
    </React.Fragment>
  )
}

const Section = styled.div`
  padding: 0 ${theme.spaces.standardPadding} 0 ${theme.spaces.standardPadding};
`

const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100% / 4 - 6vw), 2fr));
  grid-gap: 0vw;
  margin-bottom: ${theme.spaces.standardPadding};
  @media all and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 2 - 6vw), 1fr));
    grid-gap: 6vw;
  }
`

const StyledLabel = styled.label`
  font-family: ${theme.fontFamilyHeading};
  color: ${theme.colors.darkFont};
  font-size: ${theme.fontsizes.h3MediumScreen};
  text-transform: uppercase;
  line-height: 1.5;

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.h3SmallScreen};
  }
`

const StyledPictureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(100% / 2 - ${theme.spaces.standardPadding}), 1fr)
  );
  grid-gap: ${theme.spaces.standardPadding};
`

const ImageSubline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  align-items: center;

  div > h3 {
    font-size: ${theme.fontsizes.h2MediumScreen};
    font-family: ${theme.fontFamilyHeading};
    color: ${theme.colors.darkFont};
    font-weight: bold;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: auto 0;
  }

  @media all and (max-width: 768px) {
    height: 8vw;
  }
`

const StyledText = styled.p`
  font-family: ${theme.fontFamilyHeading};
  color: ${theme.colors.darkFont};
  font-weight: bold;
  font-size: ${theme.fontsizes.h2MediumScreen};
  text-transform: uppercase;
  padding: ${theme.spaces.standardPadding} 0 0 0;
`

export default ProjectsStreetphotographyStartseite
