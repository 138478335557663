import React, { useRef } from "react"
import styled from "@emotion/styled"
import theme from "../shared/theme"
import Select, { components } from "react-select"

const DropdownSelect = ({ defaultValue, optionen, onChange }) => {

  //reingegeben wird ein einfaches Array aus Strings - react-select benötigt aber ein array aus Objekten mit den keys label und value
  const convertStringToObjectArray = optionen => {
    const optionenObjectArray = []
    optionen.map(option => {
      optionenObjectArray.push({
        label: option,
        value: option
      })
      return optionenObjectArray
    })
    return optionenObjectArray
  }

  //da hooks kein componenWillMount verfügbar - wird hier ersetzt, damit array für die Optionen vor dem ersten render verfügbar
  const useComponentWillMount = optionen => {
    const willMount = useRef(true)

    if (willMount.current) {
      const optionenObjectArray = convertStringToObjectArray(optionen)
      return optionenObjectArray
    }

    willMount.current = false
  }

  //Styling der Komponenten im Select
  const IndicatorsContainer = props => {
    return (
      <div
        style={{
          background: "transparent",
          width: "2vw",
          margin: "auto",
          color: `${theme.colors.darkFont}`
        }}
      >
        <components.IndicatorsContainer {...props} />
      </div>
    )
  }

  const ValueContainer = props => {
    return (
      <div
        style={{
          width: "20vw",
          margin: "auto",
          fontFamily: `${theme.fontFamily}`,
          fontSize: `${theme.fontsizes.textMediumScreen}`,
          paddingLeft: `${theme.spaces.dropdownOptionPaddingBigScreen}`
        }}
      >
        <components.ValueContainer {...props} />
      </div>
    )
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? `${theme.colors.bodyBackground}`
        : state.isFocused
        ? `${theme.colors.bodyBackground}`
        : `${theme.colors.darkFont}`,
      background: state.isSelected
        ? `${theme.colors.darkFont}`
        : state.isFocused
        ? `${theme.colors.focusedElement}`
        : `${theme.colors.bodyBackground}`,
      padding: `${theme.spaces.dropdownOptionPaddingBigScreen}`,
      fontFamily: `${theme.fontFamily}`,
      fontSize: `${theme.fontsizes.textMediumScreen}`,
      width: "20vw",
      "@media all and (max-width: 768px)": {
        padding: `${theme.spaces.smallSpacing}`,
        fontSize: `${theme.fontsizes.textSmallScreen}`,
        width: "41vw"
      }
    }),
    control: () => {
      return {
        border: `${theme.lineStrength.buttons} solid ${theme.colors.darkFont}`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "20vw",
        height: "3vw",
        "@media all and (max-width: 768px)": {
          height: "5vw",
          width: "41vw",
        }
      }
    },
    indicatorSeparator: () => ({
      display: "none"
    }),
    menuList: () => ({
      width: "20vw",
      border: `${theme.lineStrength.buttons} solid ${theme.colors.darkFont}`,
      "@media all and (max-width: 768px)": {
        width: "41vw",
      }
    }),
    menu: provided => ({
      ...provided,
      width: "20vw",
      marginLeft: ".1vw",
      "@media all and (max-width: 768px)": {
        width: "41vw",
        marginLeft: "0vw",
      }
    }),
    valueContainer: () => ({
      "@media all and (max-width: 768px)": {
        fontSize: `${theme.fontsizes.textSmallScreen}`,
        marginLeft: "-3.5vw"
      }
    })
  }

  return (
    <CustomSelectWrapper>
      <Select
        styles={customStyles}
        options={useComponentWillMount(optionen)}
        onChange={onChange}
        placeholder={defaultValue}
        isSearchable={false}
        tabIndex={0}
        openMenuOnFocus
        components={{
          IndicatorsContainer,
          ValueContainer
        }}
      />
    </CustomSelectWrapper>
  )
}

const CustomSelectWrapper = styled.div`
  margin-left: 3.3vw;

  @media all and (max-width: 768px) {
    margin-left: 0;
  }
`

export default DropdownSelect
